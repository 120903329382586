import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image1 from '../images/home2.webp';
import Image2 from '../images/home3.webp';
import Image3 from '../images/home4.webp';
import Image4 from '../images/home5.webp';
import Image5 from '../images/home6.webp';
import Image6 from '../images/home7.webp';
import Image7 from '../images/home8.webp';
import Image8 from '../images/home9.webp';
import Image9 from '../images/home1.webp';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles1.css'; // Assume this contains our custom styles

function ImageSlider() {
  const cardsData = [
    { image: Image1 },
    { image: Image2 },
    { image: Image3 },
    { image: Image4 },
    { image: Image5 },
    { image: Image6 },
    { image: Image7 },
    { image: Image8 },
    { image: Image9 },
  ];

  // Group cards into sets for different screen sizes
  const groupCards = (cards, groupSize) => {
    const groupedCards = [];
    for (let i = 0; i < cards.length; i += groupSize) {
      groupedCards.push(cards.slice(i, i + groupSize));
    }
    return groupedCards;
  };

  const groupedCardsDesktop = groupCards(cardsData, 3); // 3 cards per slide for desktop
  const groupedCardsTablet = groupCards(cardsData, 2); // 2 cards per slide for tablet

  return (
    <div className="image-slider-section" style={{ padding: '20px', color: '#fff' }}>
      {/* Desktop Carousel (3 cards per slide) */}
      <div className="d-none d-lg-block">
        <Carousel interval={2000} indicators={groupedCardsDesktop.length > 1}>
          {groupedCardsDesktop.map((group, idx) => (
            <Carousel.Item key={idx}>
              <Container>
                <Row className="justify-content-center">
                  {group.map((card, index) => (
                    <Col key={index} lg={4} style={{ padding: '0 10px' }}>
                      <Card  style={{ width: '100%', overflow: 'hidden', marginBottom: '20px', border: 'none',borderRadius: '25px' }}>
                        <img 
                          src={card.image} 
                          alt={`Slide ${idx * 3 + index}`} 
                          style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '25px' }}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      
      {/* Tablet Carousel (2 cards per slide) */}
      <div className="d-none d-md-block d-lg-none">
        <Carousel interval={2000} indicators={groupedCardsTablet.length > 1}>
          {groupedCardsTablet.map((group, idx) => (
            <Carousel.Item key={idx}>
              <Container>
                <Row className="justify-content-center">
                  {group.map((card, index) => (
                    <Col key={index} md={6} style={{ padding: '0 10px' }}>
                      <Card style={{ width: '100%', overflow: 'hidden', marginBottom: '20px', border: 'none',borderRadius: '25px' }}>
                        <img 
                          src={card.image} 
                          alt={`Slide ${idx * 2 + index}`} 
                          style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '25px' }}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      {/* Mobile Carousel (1 card per slide) */}
      <div className="d-md-none">
        <Carousel interval={2000} indicators={cardsData.length > 1}>
          {cardsData.map((card, idx) => (
            <Carousel.Item key={idx}>
              <Container>
                <Row className="justify-content-center row1">
                  <Col xs={12} style={{ padding: '0 10px' }}>
                    <Card className="custom-card23" style={{ width: '100%', overflow: 'hidden', marginBottom: '20px', border: 'none',borderRadius: '25px' }}>
                      <img 
                        src={card.image} 
                        alt={`Slide ${idx}`} 
                        style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '25px' }}
                      />
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default ImageSlider;
