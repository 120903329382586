import React from "react";
import Hero from "../components/Hero/Hero";

const App = ({ pageRef }) => {
  return (
    <div ref={pageRef}>
      <br></br>
      <br></br>
      <br></br>
      <Hero/>
      <br></br>
    </div>
  );
};

export default App;
