import React, { useState, useEffect } from 'react';
import './Hero.css'; // Assuming you have some CSS for styling

const CountdownTimer = ({ eventDate }) => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const nowDate = new Date();
            const totalSeconds = (new Date(eventDate) - nowDate) / 1000;

            const days = Math.floor(totalSeconds / 3600 / 24);
            const hours = Math.floor(totalSeconds / 3600) % 24;
            const minutes = Math.floor(totalSeconds / 60) % 60;
            const seconds = Math.floor(totalSeconds) % 60;

            setDays(formatTime(days));
            setHours(formatTime(hours));
            setMinutes(formatTime(minutes));
            setSeconds(formatTime(seconds));
        }, 1000);

        return () => clearInterval(interval);
    }, [eventDate]);

    function formatTime(time) {
        return time < 10 ? '0' + time : time;
    }

    return (
        <div className='flexCenter stats' id="c1">
            <div className='flexColCenter stat glass-container'>
                <span className='d1'>{days}</span>
                <span className='secondaryText'>Days</span>
            </div>
            <div className='flexColCenter stat glass-container'>
                <span className='d1'>{hours}</span>
                <span className='secondaryText'>Hours</span>
            </div>
            <div className='flexColCenter stat glass-container'>
                <span className='d1'>{minutes}</span>
                <span className='secondaryText'>Minutes</span>
            </div>
            <div className='flexColCenter stat glass-container'>
                <span className='d1'>{seconds}</span>
                <span className='secondaryText'>Seconds</span>
            </div>
        </div>
    );
};

export default CountdownTimer;
