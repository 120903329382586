import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import './Hero.css';
import CountdownTimer from './CountdownTimer.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';



const Hero = () => {
    
    const eventDate = "2024-06-09T15:00:00";
    return (
        <section className='hero-wrapper'>
        
    <div class="top-center-container">
        <div class="logo-wrapper">
            <img src="./logo-min.webp" alt="logo" width="150" id="logo-image1" class="logo-image"/>
        </div>
    <div class="vertical-line"></div>
    <div class="logo-wrapper" id="logo-image21">
        <img src="./slogan.svg" alt="logo" width="250" id="logo-image2" class="logo-image"/>
     </div>
    
    </div>
            
            
              {/* <ParticleNetworkAnimation/> */}
            <div className='paddings innerWidth hero-container'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 hero-right">
                            <motion.div
                                initial={{ x: "-100vw", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 2, type: "ease-in" }}
                            >
                                <div className='flexCenter1' id="passage">
                                    <span className='secondaryText'>Make Ideas Happen</span>
                                    <br/>
                                </div>
                                <div className='hero-title' >
                                    <h1>COMING<br/> SOON!</h1>
                                </div>
                                <CountdownTimer eventDate={eventDate}/>
                            </motion.div>
                        </div>

                        <div className="col-12 col-md-6 hero-left">
                            <motion.div
                                className='image-container'
                                initial={{ x: "100vw", opacity: 0 }} // Start from outside the viewport on the left
                                animate={{ x: 0, opacity: 1 }} // Move to the right (x: 0)
                                transition={{ duration: 2, type: "ease-in" }}
                            >
                                <img src='./mascot-min1.webp' alt=''/>
                            </motion.div>
                        </div>
                    </div>
                </div>

                {/* <div className="container1">
                    <div>
                        <img src="./MIT-min.webp" alt='MIT logo' width="125" id="mit"/>
                    </div>
                    <div id="div2">
                        <img src="./IMSSA-min.webp" alt='IMSSA logo' width="60"/>
                    </div>
                    <div>
                        <img src="./uok-min.webp" alt='UOK logo' width="60"/>
                    </div>
                </div> */}
            </div>
        </section>
    );
};

export default Hero;
