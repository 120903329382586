// src/App.js
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NavBar from "./components/NavBar";
import WhatIsHackXPage from "./pages/whatIsHackX";
import TimeLine from "./pages/TimeLine";
import ProcessDetails from "./pages/Process";
import Team from "./pages/OurTeam";
import Awards from "./pages/Award";
import GalleryDetails from "./pages/Gallery";
import FAQ from "./pages/FAQ";
import Footer from "./components/Footer";
import ParticlesComponent from "./components/ParticlesComponent";
import ScrollToTopButton from "./components/Timeline1/ScrollToTop/ScrollToTopButton"; // Import the component
import Spinner from "./components/Spinner/Spinner";

function App() {
  const [loading, setLoading] = useState(true);

  const homeRef = useRef(null);
  const timelineRef = useRef(null);
  const processRef = useRef(null);
  const awardRef = useRef(null);
  const galleryRef = useRef(null);
  const faqsRef = useRef(null);
  const contactRef = useRef(null);
  const aboutRef = useRef(null);

  useEffect(() => {
    // Simulate a data fetch or any async operation
    setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds
  }, []);

  return (
    <div className="App">
      {/* {loading && <Spinner />} */}
      <ParticlesComponent />
      <BrowserRouter>
        <NavBar
          homeRef={homeRef}
          aboutRef={aboutRef}
          timelineRef={timelineRef}
          processRef={processRef}
          awardRef={awardRef}
          galleryRef={galleryRef}
          contactRef={contactRef}
          faqsRef={faqsRef}
        />
        <section>
          <Routes>
            <Route path="/" element={<TimeLine pageRef={timelineRef} />} />
          </Routes>
        </section>
        <section>
          <Routes>
            <Route path="/" element={<WhatIsHackXPage pageRef={homeRef} />} />
          </Routes>
        </section>
        <br />
        <section>
          <Routes>
            <Route path="/" element={<Team pageRef={contactRef} />} />
          </Routes>
        </section>
        <Footer
          homeRef={homeRef}
          aboutRef={aboutRef}
          timelineRef={timelineRef}
          processRef={processRef}
          awardRef={awardRef}
          galleryRef={galleryRef}
          contactRef={contactRef}
          faqsRef={faqsRef}
        />
        <ScrollToTopButton /> {/* Add ScrollToTopButton component here */}
      </BrowserRouter>
    </div>
  );
}

export default App;
