import React from 'react';
import { SocialIcon } from 'react-social-icons';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <SocialIcon url="https://www.facebook.com/imhackx" style={{ marginRight: '10px' }} />
      <SocialIcon url="https://www.instagram.com/hackx_uok/==" bgColor="#7D38AD" style={{ marginRight: '10px' }} />
      <SocialIcon url="https://www.youtube.com/@hackx_uok" style={{ marginRight: '10px' }} />
      <SocialIcon url="https://www.tiktok.com/@hackx_uok" style={{ marginRight: '10px' }} />
      {/* Add more SocialIcon components with different URLs as needed */}
    </div>
  );
};

export default Sidebar;
