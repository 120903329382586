import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MDBCarouselItem } from 'mdb-react-ui-kit';
import carouselImage1 from '../images/home2.webp'; // Adjust the path as needed
import carouselImage2 from '../images/home4.webp'; // Adjust the path as needed
import carouselImage3 from '../images/home5.webp'; // Adjust the path as needed
import "./App.css";

export default function App() {
  const carouselRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const imageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '25px',
    maxWidth: '550px',
    margin: 'auto',
  };

  const handlePrev = () => {
    if (isMounted && carouselRef.current) {
      carouselRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (isMounted && carouselRef.current) {
      carouselRef.current.slickNext();
    }
  };

  const settings = {
    dots: true, // Show dots navigation
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false, // Hide arrows
  };

  return (
    <div className="slick-carousel-container">
      <Slider ref={carouselRef} {...settings}>
        <MDBCarouselItem interval={2000} className='carousel1'>
          <div className='carousel-controls'>
            <button className="carousel-control-prev" onClick={handlePrev} style={{marginLeft:"10px"}}>
              &#10094; {/* Left arrow */}
            </button>
          </div>
          <img src={carouselImage1} className='d-block w-100' style={imageStyle} alt='First slide' />
          <div className='carousel-controls'>
            <button className="carousel-control-next" onClick={handleNext} style={{marginRight:"10px"}}>
              &#10095; {/* Right arrow */}
            </button>
          </div>
        </MDBCarouselItem>
        <MDBCarouselItem interval={2000} className='carousel1'>
          <div className='carousel-controls'>
            <button className="carousel-control-prev" onClick={handlePrev} style={{marginLeft:"10px"}}>
              &#10094; {/* Left arrow */}
            </button>
          </div>
          <img src={carouselImage2} className='d-block w-100' style={imageStyle} alt='Second slide' />
          <div className='carousel-controls'>
            <button className="carousel-control-next" onClick={handleNext} style={{marginRight:"10px"}}>
              &#10095; {/* Right arrow */}
            </button>
          </div>
        </MDBCarouselItem>
        <MDBCarouselItem interval={2000} className='carousel1'>
          <div className='carousel-controls'>
            <button className="carousel-control-prev" onClick={handlePrev} style={{marginLeft:"10px"}}>
              &#10094; {/* Left arrow */}
            </button>
          </div>
          <img src={carouselImage3} className='d-block w-100' style={imageStyle} alt='Third slide' />
          <div className='carousel-controls'>
            <button className="carousel-control-next" onClick={handleNext} style={{marginRight:"10px"}}>
              &#10095; {/* Right arrow */}
            </button>
          </div>
        </MDBCarouselItem>
      </Slider>
    </div>
  );
}